import React, { useState } from "react";
import { useFormik } from "formik";
import bgMain from "../../assets/images/contact/main.jpg";
import bgMainRes from "../../assets/images/contact/main-res.png";
import countries from "./countries.json";
import Fade from "react-reveal/Fade";
import Axios from "axios";

export default function ContactPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      business: "",
      country: "",
      subject: "",
      message: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      if (
        !values.name ||
        !values.business ||
        !values.email ||
        !values.message ||
        !values.country ||
        !values.subject
      ) {
        alert("Incomplete data!, please complete all fields");
        setIsLoading(false);
      } else {
        const data = {
          to: "federicopacas@cafetuxpal.com",
          from: "hola@12aeme.studio",
          subject: "Contacto Website",
          cc: ["angelcaceres97@gmail.com", "marketing@cafetuxpal.com"],
          html: `
          <div className="">
            <p>Un usuario quiere establecer contacto con Café Tuxpal, a continuación sus datos:</p>
            <table>
              <tr>
                <td>Nombre:</td>
                <td>${values.name}</td>
              </tr>
              <tr>
                <td>Asunto:</td>
                <td>${values.subject}</td>
              </tr>
              <tr>
                <td>Nombre de empresa:</td>
                <td>${values.business}</td>
              </tr>
              <tr>
                <td>País:</td>
                <td>${values.country}</td>
              </tr>
              <tr>
                <td>Correo de contacto:</td>
                <td>${values.email}</td>
              </tr>
              <tr>
                <td>Mensaje:</td>
                <td>${values.message}</td>
              </tr>
            </table>
          </div>
          `,
        };

        Axios.post(
          "https://us-central1-proyecto-unete.cloudfunctions.net/utils/mailer",
          data
        )
          .then((res) => {
            if (res.data.message === "Exito") {
              setIsSuccess(true);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            alert("Something went wrong, try again later.");
            throw error;
          });

        const data2 = {
          to: "caceres840@gmail.com",
          from: "websites@12aeme.studio",
          subject: "Contacto Website",
          html: `
          <div className="">
            <p>Un usuario quiere establecer contacto con Café Tuxpal, a continuación sus datos:</p>
            <table>
              <tr>
                <td>Nombre:</td>
                <td>${values.name}</td>
              </tr>
              <tr>
                <td>Asunto:</td>
                <td>${values.subject}</td>
              </tr>
              <tr>
                <td>Nombre de empresa:</td>
                <td>${values.business}</td>
              </tr>
              <tr>
                <td>País:</td>
                <td>${values.country}</td>
              </tr>
              <tr>
                <td>Correo de contacto:</td>
                <td>${values.email}</td>
              </tr>
              <tr>
                <td>Mensaje:</td>
                <td>${values.message}</td>
              </tr>
            </table>
          </div>
          `,
        };

        Axios.post(
          "https://us-central1-proyecto-unete.cloudfunctions.net/utils/mailer",
          data2
        )
          .then((res) => {
            if (res.data.message === "Exito") {
              setIsSuccess(true);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            alert("Something went wrong, try again later.");
            throw error;
          });
      }
    },
  });

  return (
    <div className="overflow-hidden">
      <div className="lg:h-header 2xl:h-headerxl -my-20 relative max-h-screen overflow-hidden">
        <Fade>
          <img
            src={bgMain}
            alt=""
            className="h-full w-full object-cover hidden lg:block"
          />
        </Fade>
        <Fade>
          <img
            src={bgMainRes}
            alt=""
            className="h-full w-full object-cover lg:hidden mt-20"
          />
        </Fade>
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-darkBlue bg-opacity-50">
          <Fade bottom distance="50%">
            <h2 className=" text-white font-medium font-play italic text-6xl lg:text-7xl text-center mt-32">
              Contact Us
            </h2>
          </Fade>
        </div>
      </div>

      <div className="mt-32 lg:mt-48 bg-white container mx-auto lg:px-32 pb-40 px-5">
        <form onSubmit={formik.handleSubmit}>
          <Fade bottom distance="50%">
            <div className="lg:flex lg:space-x-6 ">
              <input
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
                className="lg:w-1/2 w-full appearance-none border-2 border-gold py-3 placeholder-gold px-3 focus:outline-none text-darkBlue font-bold font-chivo"
                placeholder="Nombre / Name"
              />
              <input
                name="email"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.email}
                className="lg:w-1/2 w-full appearance-none border-2 border-gold py-3 placeholder-gold px-3 focus:outline-none text-darkBlue font-bold font-chivo mt-4 lg:mt-0"
                placeholder="Correo / Email"
              />
            </div>
          </Fade>
          <Fade bottom distance="50%">
            <div className="lg:flex lg:space-x-6 mt-4">
              <input
                name="business"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.business}
                className="lg:w-1/2 w-full appearance-none border-2 border-gold py-3 placeholder-gold px-3 focus:outline-none text-darkBlue font-bold font-chivo"
                placeholder="Empresa / Business Name"
              />
              <div className="relative lg:w-1/2 w-full mt-4 lg:mt-0">
                <select
                  component="select"
                  name="country"
                  onChange={formik.handleChange}
                  className="block w-full appearance-none border-2 border-gold py-3 placeholder-gold px-3 focus:outline-none text-gold font-bold font-chivo"
                >
                  <option value="NY" className="font-medium py-1">
                    País / Country
                  </option>
                  {countries.map((country, i) => (
                    <option
                      key={i}
                      value={country.name}
                      className="font-medium py-1"
                    >
                      {country.name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gold">
                  <svg
                    className="fill-current w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 14"
                  >
                    <path d="M9.353 14L.706 0H18z" />
                  </svg>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom distance="50%">
            <div className="flex space-x-6 mt-4">
              <div className="relative w-full">
                <select
                  component="select"
                  name="subject"
                  onChange={formik.handleChange}
                  className="block w-full appearance-none border-2 border-gold py-3 placeholder-gold px-3 focus:outline-none text-gold font-bold font-chivo"
                >
                  <option value="" className=" font-medium">
                    Asunto / Subject
                  </option>
                  <option value="Information" className=" font-medium">
                    Information / Información
                  </option>
                  <option value="Coffee Offerings" className=" font-medium">
                    Coffee Offerings
                  </option>
                  <option
                    value="Milling or Export Services"
                    className=" font-medium"
                  >
                    Milling or Export Services / Servicios de Beneficiado o
                    Exportación
                  </option>
                  <option value="Press" className=" font-medium">
                    Press
                  </option>
                  <option value="Other" className=" font-medium">
                    Other
                  </option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gold">
                  <svg
                    className="fill-current w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 14"
                  >
                    <path d="M9.353 14L.706 0H18z" />
                  </svg>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom distance="50%">
            <div className="flex space-x-6 mt-4">
              <textarea
                name="message"
                rows={5}
                onChange={formik.handleChange}
                value={formik.values.message}
                className="block w-full appearance-none border-2 border-gold py-3 placeholder-gold px-3 focus:outline-none text-darkBlue font-bold resize-none"
                placeholder="Mensaje / Message"
              ></textarea>
            </div>
          </Fade>
          <Fade bottom distance="50%">
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isLoading || isSuccess}
                className={`uppercase font-bold text-white font-chivo py-2 px-16 self-center mt-10 focus:outline-none ${
                  isSuccess ? " bg-gold cursor-auto" : "bg-darkBlue"
                }`}
              >
                {isLoading && "Loading..."}
                {!isLoading && isSuccess && "Done"}
                {!isLoading && !isSuccess && "Send"}
              </button>
            </div>
          </Fade>
        </form>
      </div>
    </div>
  );
}
