import React, { useState } from "react";
import { useFormik } from "formik";
import bgMain from "../../assets/images/offerings/main.jpg";
import bgMainRes from "../../assets/images/offerings/main-res.jpg";
import countries from "../Contact/countries.json";
import Fade from "react-reveal/Fade";
import Axios from "axios";

export default function OfferingPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      business: "",
      country: "",
      process: "",
      score: "",
      varietyPreferences: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      if (
        !values.name ||
        !values.business ||
        !values.email ||
        !values.process ||
        !values.country ||
        !values.score
      ) {
        alert("Incomplete data!, please complete all fields");
        setIsLoading(false);
      } else {
        const data = {
          to: "federicopacas@cafetuxpal.com",
          from: "hola@12aeme.studio",
          subject: "Contact Offering Website",
          cc: ["caceres840@gmail.com", "marketing@cafetuxpal.com"],
          html: `
          <div className="">
            <p>Un usuario completó el formulario de Offerings, a continuación los datos:</p>
            <table>
              <tr>
                <td>Name:</td>
                <td>${values.name}</td>
              </tr>
              <tr>
                <td>Company:</td>
                <td>${values.business}</td>
              </tr>
              <tr>
                <td>Country:</td>
                <td>${values.country}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>${values.email}</td>
              </tr>
              <tr>
                <td>Process:</td>
                <td>${values.process}</td>
              </tr>
              <tr>
                <td>Score Minimum:</td>
                <td>${values.score}</td>
              </tr>
              <tr>
                <td>Variety Preferences:</td>
                <td>${values.varietyPreferences}</td>
              </tr>
            </table>
          </div>
          `,
        };

        Axios.post(
          "https://us-central1-proyecto-unete.cloudfunctions.net/utils/mailer",
          data
        )
          .then(() => {
            const data2 = {
              to: values.email,
              from: "hola@12aeme.studio",
              subject: "Request Confirmed from Café Tuxpal",
              html: `
                <div className="">
                  Thanks for your request! We'll get in touch soon.
                </div>
                `,
            };

            Axios.post(
              "https://us-central1-proyecto-unete.cloudfunctions.net/utils/mailer",
              data2
            )
              .then((res) => {
                const data3 = {
                  to: "andrea@cafetuxpal.com",
                  from: "hola@12aeme.studio",
                  subject: "Contact Offering Website",
                  html: `
                  <div className="">
                    <p>Un usuario completó el formulario de Offerings, a continuación los datos:</p>
                    <table>
                      <tr>
                        <td>Name:</td>
                        <td>${values.name}</td>
                      </tr>
                      <tr>
                        <td>Company:</td>
                        <td>${values.business}</td>
                      </tr>
                      <tr>
                        <td>Country:</td>
                        <td>${values.country}</td>
                      </tr>
                      <tr>
                        <td>Email:</td>
                        <td>${values.email}</td>
                      </tr>
                      <tr>
                        <td>Process:</td>
                        <td>${values.process}</td>
                      </tr>
                      <tr>
                        <td>Score Minimum:</td>
                        <td>${values.score}</td>
                      </tr>
                      <tr>
                        <td>Variety Preferences:</td>
                        <td>${values.varietyPreferences}</td>
                      </tr>
                    </table>
                  </div>
                  `,
                };

                Axios.post(
                  "https://us-central1-proyecto-unete.cloudfunctions.net/utils/mailer",
                  data3
                )
                  .then(() => {
                    if (res.data.message === "Exito") {
                      setIsSuccess(true);
                    }
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    alert("Something went wrong, try again later.");
                    throw error;
                  });
              })
              .catch((error) => {
                setIsLoading(false);
                alert("Something went wrong, try again later.");
                throw error;
              });
          })
          .catch((error) => {
            setIsLoading(false);
            alert("Something went wrong, try again later.");
            throw error;
          });
      }
    },
  });

  return (
    <div className="overflow-hidden">
      <div className="lg:h-header 2xl:h-headerxl max-h-screen -my-20 relative overflow-hidden">
        <Fade>
          <img
            src={bgMain}
            alt=""
            className="h-full w-full object-cover hidden lg:block"
          />
        </Fade>
        <Fade>
          <img
            src={bgMainRes}
            alt=""
            className="h-full w-full object-cover lg:hidden mt-20"
          />
        </Fade>
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-darkBlue bg-opacity-50">
          <Fade bottom distance="50%">
            <h2 className=" text-mint font-medium font-play italic text-6xl lg:text-7xl textx text-center mt-32">
              Featured Offerings
            </h2>
          </Fade>
          <Fade bottom delay={500} distance="50%">
            <p className=" text-white text-xl text-center font-chivo mt-5 px-5">
              We offer the world great coffees and a truly important part of our{" "}
              <br /> family tradition.
            </p>
          </Fade>
        </div>
      </div>

      <div className="bg-white mt-32 container mx-auto px-5 lg:px-10 pb-20">
        <Fade bottom distance="50%">
          <h3 className=" text-darkBlue text-2xl lg:text-3xl font-chivo font-bold uppercase mb-10 text-center lg:text-left">
            Try our 21/22 Harvest!
          </h3>
          <p className="text-grayText font-chivo text-xl mt-5">
            Request a sample to taste the best of El Salvador.
          </p>
        </Fade>

        <div className="mt-10 lg:mt-16 bg-white container mx-auto lg:px-32 pb-40">
          <form onSubmit={formik.handleSubmit}>
            <Fade bottom distance="50%">
              <div className="lg:flex lg:space-x-6">
                <div className="lg:w-1/2 w-full">
                  <label htmlFor="" className="font-bold flex text-darkBlue">
                    Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="appearance-none border-2 w-full border-mint py-3 placeholder-lightBlue px-3 focus:outline-none text-darkBlue font-bold font-chivo mt-2"
                    placeholder="Who's requesting this sample?"
                  />
                </div>
                <div className="lg:w-1/2 w-full mt-4 lg:mt-0">
                  <label htmlFor="" className="font-bold flex text-darkBlue">
                    Email
                  </label>
                  <input
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className="w-full appearance-none border-2 border-mint py-3 placeholder-lightBlue px-3 focus:outline-none text-darkBlue font-bold font-chivo mt-2"
                    placeholder="Where can we contact you?"
                  />
                </div>
              </div>
            </Fade>
            <Fade bottom distance="50%">
              <div className="lg:flex lg:space-x-6 mt-4">
                <div className="lg:w-1/2 w-full">
                  <label htmlFor="" className="font-bold flex text-darkBlue">
                    Company
                  </label>
                  <input
                    name="business"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.business}
                    className="w-full appearance-none border-2 border-mint py-3 placeholder-lightBlue px-3 focus:outline-none text-darkBlue font-bold font-chivo mt-2"
                    placeholder="Where do you work?"
                  />
                </div>
                <div className="lg:w-1/2 w-full mt-4 lg:mt-0">
                  <label htmlFor="" className="font-bold flex text-darkBlue">
                    Country
                  </label>
                  <div className="relative w-full">
                    <select
                      component="select"
                      name="country"
                      onChange={formik.handleChange}
                      className="block w-full appearance-none border-2 border-mint py-3 placeholder-lightBlue px-3 focus:outline-none text-darkBlue font-bold font-chivo mt-2"
                    >
                      <option value="" className="font-medium py-1">
                        Select Country
                      </option>
                      {countries.map((country, i) => (
                        <option
                          key={i}
                          value={country.name}
                          className="font-medium py-1"
                        >
                          {country.name}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3  text-darkBlue">
                      <svg
                        className="fill-current w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 14"
                      >
                        <path d="M9.353 14L.706 0H18z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>

            <Fade bottom distance="50%">
              <div className="lg:flex lg:space-x-6 mt-4">
                <div className="lg:w-1/2 w-full mt-4 lg:mt-0">
                  <label htmlFor="" className="font-bold flex text-darkBlue">
                    Processes
                  </label>
                  <div className="relative w-full">
                    <select
                      component="select"
                      name="process"
                      onChange={formik.handleChange}
                      className="block w-full appearance-none border-2 border-mint py-3 placeholder-lightBlue px-3 focus:outline-none  text-darkBlue font-bold font-chivo mt-2"
                    >
                      <option value="" className="font-medium py-1">
                        Select Process
                      </option>
                      <option value="Washed" className="font-medium py-1">
                        Washed
                      </option>
                      <option value="Natural" className="font-medium py-1">
                        Natural
                      </option>
                      <option value="Red Honey" className="font-medium py-1">
                        Red Honey
                      </option>
                      <option value="Black Honey" className="font-medium py-1">
                        Black Honey
                      </option>
                      <option
                        value="Anaerobic-Washed"
                        className="font-medium py-1"
                      >
                        Anaerobic - Washed
                      </option>
                      <option
                        value="Anaerobic- Natural"
                        className="font-medium py-1"
                      >
                        Anaerobic - Natural
                      </option>
                      <option
                        value="Anaerobic- Red Honey"
                        className="font-medium py-1"
                      >
                        Anaerobic - Red Honey
                      </option>
                      <option
                        value="Anaerobic- Black Honey"
                        className="font-medium py-1"
                      >
                        Anaerobic - Black Honey
                      </option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3  text-darkBlue">
                      <svg
                        className="fill-current w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 14"
                      >
                        <path d="M9.353 14L.706 0H18z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="lg:w-1/2 w-full mt-4 lg:mt-0">
                  <label htmlFor="" className="font-bold flex text-darkBlue">
                    Score Minimum
                  </label>
                  <div className="relative w-full">
                    <select
                      component="select"
                      name="score"
                      onChange={formik.handleChange}
                      className="block w-full appearance-none border-2 border-mint py-3 placeholder-lightBlue px-3 focus:outline-none  text-darkBlue font-bold font-chivo mt-2"
                    >
                      <option value="" className="font-medium py-1">
                        Select Score Minimum
                      </option>
                      <option value="80" className="font-medium py-1">
                        80
                      </option>
                      <option value="82" className="font-medium py-1">
                        82
                      </option>
                      <option value="84" className="font-medium py-1">
                        84
                      </option>
                      <option value="86" className="font-medium py-1">
                        86
                      </option>
                      <option value="88" className="font-medium py-1">
                        88
                      </option>
                      <option value="90" className="font-medium py-1">
                        90
                      </option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3  text-darkBlue">
                      <svg
                        className="fill-current w-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 14"
                      >
                        <path d="M9.353 14L.706 0H18z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom distance="50%">
              <div className="flex space-x-6 mt-4">
                <div className="w-full">
                  <label htmlFor="" className="font-bold flex text-darkBlue">
                    Variety Preferences
                  </label>
                  <input
                    name="varietyPreferences"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.varietyPreferences}
                    className="w-full appearance-none border-2 border-mint py-3 placeholder-lightBlue text-sm lg:text-base px-3 focus:outline-none text-darkBlue font-bold font-chivo mt-2"
                    placeholder="Are you interested in any specific varieties?"
                  />
                </div>
              </div>
            </Fade>

            <Fade bottom distance="50%">
              <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={isLoading || isSuccess}
                  className={`uppercase font-bold text-white font-chivo py-2 px-16 self-center mt-10 focus:outline-none ${
                    isSuccess ? " bg-gold cursor-auto" : "bg-darkBlue"
                  }`}
                >
                  {isLoading && "Loading..."}
                  {!isLoading &&
                    isSuccess &&
                    "Thanks for your request! We'll get in touch soon."}
                  {!isLoading && !isSuccess && "Send Sample Request"}
                </button>
              </div>
            </Fade>
          </form>
        </div>
      </div>
    </div>
  );
}
