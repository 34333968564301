import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/images/general/arrow-buttom.svg";

export default function Buttom({
  label,
  link,
  textWhite,
  goldBorder,
  capitalize,
}) {
  return (
    <Link
      to={link}
      className={`font-chivo text-center font-bold border-2 w-56 py-3 flex items-center justify-center group relative ${
        textWhite ? "text-white" : "text-darkBlue"
      } ${capitalize ? "capitalize" : "uppercase "} ${
        goldBorder ? " border-gold" : "border-mint"
      }`}
    >
      <span className=" transform translate-x-0 group-hover:-translate-x-5 transition duration-300 ease-in-out">{label}</span>
      <span className="opacity-0 group-hover:opacity-100 inline-block transition duration-300 ease-in-out absolute right-0 mr-5"><Arrow className="fill-current h-2" /></span>
    </Link>
  );
}
