import React from "react";
import scrollIcon from "../../assets/images/homepage/icon-scroll.png";
import homeFarm from "../../assets/images/homepage/home-farms.jpg";
import homeMill from "../../assets/images/homepage/home-mill.jpg";
import bgFarm from "../../assets/images/homepage/farms-bg.png";
import bgMill from "../../assets/images/homepage/mill-bg.png";
// import bgFeatured from "../../assets/images/homepage/featured-bg.png";
import bgLive from "../../assets/images/homepage/live-bg.png";
// import homeFeat1 from "../../assets/images/homepage/home-feat-1.jpg";
// import homeFeat2 from "../../assets/images/homepage/home-feat-2.jpg";
// import homeFeat3 from "../../assets/images/homepage/home-feat-3.jpg";
import service1 from "../../assets/images/homepage/home-services-1.jpg";
import service2 from "../../assets/images/homepage/home-services-2.jpeg";
import Buttom from "../../components/Buttom";
import ReactPlayer from "react-player/lazy";
import Fade from "react-reveal/Fade";
import VideoAutoPlay from "../../components/VideoAutoPlay";

export default function HomePage() {
  return (
    <div className=" overflow-hidden">
      <div className="h-screen bg-black">
        <VideoAutoPlay />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-darkBlue bg-opacity-50 h-screen">
          <Fade bottom distance="50%">
            <h2 className=" text-mint font-medium font-play italic text-5xl lg:text-7xl textx text-center mt-20">
              The origin <br className="lg:hidden" /> of excellence
            </h2>
          </Fade>
          <Fade bottom delay={500} distance="50%">
            <p className=" text-white text-xl text-center font-chivo mt-5 px-8">
              A lifelong family tradition in the search of producing, processing{" "}
              <br className=" hidden lg:block" />
              and exporting the best coffees from El Salvador.{" "}
            </p>
          </Fade>
          <img
            src={scrollIcon}
            alt=""
            className="absolute bottom-0 mx-auto mb-10 animate-bounce"
          />
        </div>
      </div>

      <div className="container mx-auto">
        <div className="mt-10 lg:mt-20 mb-10 lg:mb-20 px-5 lg:px-0">
          <div className="lg:flex justify-between">
            <div className="relative lg:w-1/2">
              <Fade bottom distance="50%">
                <h3 className=" text-5xl lg:text-6xl font-play tracking-tight text-center lg:text-left">
                  Farms
                </h3>
              </Fade>
              <Fade bottom distance="50%">
                <p className=" font-chivo text-grayText text-lg lg:text-xl mt-5 text-center lg:text-left">
                  Our farms are the maximum expression of hard-work, resilience,
                  love for nature and almost 100 years of experience in coffee
                  production.
                </p>
              </Fade>
              <div className="lg:hidden mt-5 max-w-sm mx-auto">
                <Fade delay={500}>
                  <img src={homeFarm} alt="" />
                </Fade>
              </div>
              <div className="mt-10 lg:mt-5 relative flex justify-center lg:block z-10">
                <Fade bottom distance="50%">
                  <Buttom link="/farms" label="LEARN MORE" />
                </Fade>
              </div>
              <Fade delay={1000}>
                <img
                  src={bgFarm}
                  alt=""
                  className="absolute lg:-ml-40 -mt-5 lg:h-40"
                />
              </Fade>
            </div>
            <div className="hidden lg:block lg:w-1/3">
              <Fade delay={500}>
                <img src={homeFarm} alt="" />
              </Fade>
            </div>
          </div>
          <div className="flex justify-center mt-32 lg:mt-0">
            <span className="border lg:border-2 border-gold h-40 lg:h-64"></span>
          </div>
        </div>

        <div className="lg:mt-40 mb-10 lg:mb-20 px-5 lg:px-0">
          <div className="lg:flex justify-between mb-40">
            <Fade delay={500}>
              <div className="hidden lg:block  lg:w-1/3">
                <img src={homeMill} alt="" />
              </div>
            </Fade>
            <div className="relative lg:w-1/2">
              <Fade bottom distance="50%">
                <h3 className="text-5xl lg:text-6xl font-play tracking-tight text-center lg:text-left">
                  Mill
                </h3>
              </Fade>
              <Fade bottom distance="50%">
                <p className=" font-chivo text-grayText text-lg lg:text-xl mt-5 text-center lg:text-left">
                  Our mill has the capacity to process over{" "}
                  <span className=" font-bold">18,000 bags</span> of coffee
                  every year, where we implement both traditional and innovative
                  processing methods and have the ability to customize every
                  step for our clients.
                </p>
              </Fade>
              <div className="lg:hidden mt-5 max-w-sm mx-auto">
                <Fade delay={500}>
                  <img src={homeMill} alt="" />
                </Fade>
              </div>
              <Fade bottom distance="50%">
                <div className="mt-10 lg:mt-5 relative flex justify-center lg:block z-10">
                  <Buttom link="/mill" label="LEARN MORE" />
                </div>
              </Fade>
              <Fade delay={1000}>
                <img
                  src={bgMill}
                  alt=""
                  className="absolute lg:h-64 lg:-mr-20 -mt-10 lg:-mt-20 right-0"
                />
              </Fade>
            </div>
          </div>
          {/* <div className="flex justify-center">
            <span className="border lg:border-2 border-gold h-40 lg:h-64"></span>
          </div> */}
        </div>

        {/* <div className="mb-32 lg:mb-20">
          <Fade bottom distance="50%">
            <h3 className="text-5xl lg:text-6xl font-play tracking-tight text-center">
              Featured Offerings
            </h3>
          </Fade>
          <Fade bottom>
            <div className="flex lg:justify-around pt-10 2xl:pt-20 w-full overflow-x-auto">
              <div className="px-5 relative">
                <div className="lg:opacity-0 hover:opacity-100 absolute inset-0 bg-darkBlue bg-opacity-50 flex flex-col justify-center items-center mx-5 transition ease-in-out duration-300">
                  <p className=" text-gold text-3xl font-play tracking-tight">
                    Santa Petrona
                  </p>
                  <p className=" uppercase text-white text-lg mt-2 font-chivo font-bold">
                    bourbon
                  </p>
                  <p className="text-gold text-3xl font-play tracking-tight">Coming Soon</p>
                </div>
                <div className="w-64 h-64">
                  <img
                    src={homeFeat1}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
              <div className="px-5 relative">
                <div className="lg:opacity-0 hover:opacity-100 absolute inset-0 bg-darkBlue bg-opacity-50 flex flex-col justify-center items-center mx-5 transition ease-in-out duration-300">
                  <p className=" text-gold text-3xl font-play tracking-tight">
                    Finca San Jose
                  </p>
                  <p className=" uppercase text-white text-lg mt-2 font-chivo font-bold">
                    Pacamara
                  </p>
                  <p className="text-gold text-3xl font-play tracking-tight">Coming Soon</p>
                </div>
                <div className="w-64 h-64">
                  <img
                    src={homeFeat2}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
              <div className="px-5 relative">
                <div className="lg:opacity-0 hover:opacity-100 absolute inset-0 bg-darkBlue bg-opacity-50 flex flex-col justify-center items-center mx-5 transition ease-in-out duration-300">
                  <p className=" text-gold text-3xl font-play tracking-tight">
                    Vista Hermosa
                  </p>
                  <p className=" uppercase text-white text-lg mt-2 font-chivo font-bold">
                    Sarchimor
                  </p>
                  <p className="text-gold text-3xl font-play tracking-tight">Coming Soon</p>
                </div>
                <div className="w-64 h-64">
                  <img
                    src={homeFeat3}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
            </div>
          </Fade>
          <Fade delay={1000}>
            <div className="absolute inset-x-0 flex justify-center mt-20 lg:mt-5 mb-10">
              <img src={bgFeatured} alt="" className="lg:h-64" />
            </div>
          </Fade>
          <Fade bottom distance="50%">
            <div className="mt-10 lg:mt-40 flex justify-center relative">
              <Buttom link="/offerings" label="View Offerings" />
            </div>
          </Fade>
        </div> */}
      </div>

      <div className=" bg-darkBlue">
        <Fade bottom distance="50%">
          <h3 className="text-5xl lg:text-6xl font-play tracking-tight text-center text-white py-10 lg:py-20">
            Other Services
          </h3>
        </Fade>
        <div className="container mx-auto px-5 lg:px-0">
          <div className="lg:flex">
            <Fade delay={400}>
              <div className="lg:w-1/2 flex flex-col lg:flex-row">
                <div className=" bg-lightBlue p-10 lg:w-7/12 order-2 lg:order-1">
                  <h4 className=" text-white text-xl">Milling Services</h4>
                  <p className=" font-play text-white mt-10 lg:mt-20 mb-12 lg:mb-0 text-3xl">
                    We do customized milling for producers, importers and
                    roasters.
                  </p>
                </div>
                <div className="lg:w-5/12  h-48 lg:h-auto order-1 lg:order-2">
                  <img
                    src={service1}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
            </Fade>
            <Fade delay={400}>
              <div className="lg:w-1/2 flex flex-col lg:flex-row">
                <div className=" bg-mint p-10 lg:w-7/12 order-2 lg:order-1">
                  <h4 className=" text-white text-xl">Export Services</h4>
                  <p className=" font-play text-white mt-10 lg:mt-20 text-3xl">
                    We help producers save time, money and paperwork.
                  </p>
                </div>
                <div className="lg:w-5/12 h-48 lg:h-auto order-1 lg:order-2">
                  <img
                    src={service2}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
            </Fade>
          </div>
          <Fade bottom distance="50%">
            <div className="flex justify-center pt-16 pb-20">
              <Buttom link="/contact" label="CONTACT US" textWhite />
            </div>
          </Fade>
        </div>
      </div>

      <div className="">
        <div className="flex justify-center py-10 lg:py-20">
          <span className="border lg:border-2 border-gold h-40 lg:h-64"></span>
        </div>
        <Fade bottom distance="50%">
          <h3 className="text-5xl lg:text-6xl font-play tracking-tight text-center text-darkBlue">
            Livestream
          </h3>
        </Fade>
        <div className="mt-10 mx-auto max-w-2xl 2xl:max-w-4xl">
          <div className="">
            <div
              style={{
                position: "relative",
                paddingTop: "56.25%",
              }}
            >
              <ReactPlayer
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                url="https://www.youtube.com/embed/ny3qlRF234w"
                width="100%"
                height="100%"
                volume={0}
                muted
                loop
                playsinline
                playing
              />
            </div>
          </div>
        </div>
        <Fade delay={1000}>
          <div className="flex justify-center pt-10 pb-32 -mx-10 lg:mx-0">
            <div className="">
              <img src={bgLive} alt="" className="lg:h-20" />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
