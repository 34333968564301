import React from "react";
import bgMain from "../../assets/images/farms/main.jpg";
import bgMainRes from "../../assets/images/farms/main-res.jpg";
import iconDownload from "../../assets/images/farms/icon-download.png";
import offerings from "./data.json";
import Fade from "react-reveal/Fade";

export default function FarmsPage() {
  return (
    <div className="overflow-hidden">
      <div className="lg:h-header 2xl:h-headerxl -my-20 relative max-h-screen overflow-hidden">
        <Fade>
          <img
            src={bgMain}
            alt=""
            className="h-full w-full object-cover hidden lg:block"
          />
        </Fade>
        <Fade>
          <img
            src={bgMainRes}
            alt=""
            className="h-full w-full object-cover lg:hidden mt-20"
          />
        </Fade>
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-darkBlue bg-opacity-50">
          <Fade bottom distance="50%">
            <h2 className=" text-gold font-medium font-play italic text-6xl lg:text-7xl text-center mt-32">
              Our Farms
            </h2>
          </Fade>
          <Fade bottom delay={500} distance="50%">
            <p className=" text-white text-lg lg:text-xl text-center font-chivo mt-5 px-5">
              Our farms are the home to great stories, a vast flora and fauna,{" "}
              <br className="hidden lg:block" /> and where the excellence of
              coffee is born. Through the years, we{" "}
              <br className="hidden lg:block" /> have been able to produce
              greatness from our 7 farms.
            </p>
          </Fade>
        </div>
      </div>

      <div className=" container mx-auto bg-white mt-32 mb-64">
        {offerings.map((offering, key) => (
          <div key={key} className=" h-full">
            <Fade bottom distance="50%" className=" h-full">
              <div className="bg-grayIcon p-10 mt-10 hidden lg:flex h-full">
                <div className="w-1/2">
                  <h2 className="text-5xl font-play tracking-tight text-darkBlue">
                    {offering.name}
                  </h2>
                  <div className="border-b w-64 border-darkBlue"></div>
                  <p className=" text-grayText font-chivo text-xl mt-5">
                    Date:{" "}
                    <span className=" text-darkBlue">{offering.date}</span>
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-2">
                    Country:{" "}
                    <span className=" text-darkBlue">{offering.country}</span>
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-2">
                    Region:{" "}
                    <span className=" text-darkBlue">{offering.region}</span>
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-2">
                    Elevation:{" "}
                    <span className=" text-darkBlue">{offering.elevation}</span>
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-2">
                    Size:{" "}
                    <span className=" text-darkBlue">{offering.size}</span>
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-2">
                    Varietals:{" "}
                    <span className=" text-darkBlue">{offering.varietals}</span>
                  </p>

                  
                  <a
                    href={`${window.location.origin}/${offering.pdfName}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="border-2 border-mint uppercase py-2 relative flex justify-center cursor-pointer mt-5 w-100"
                  >
                    <span className="text-center font-bold font-chivo text-mint">
                      Download Farm Profile
                    </span>
                    <span className="absolute inset-y-0 right-0 mr-5 flex items-center">
                      <div className="">
                        <img src={iconDownload} alt="" className="h-6" />
                      </div>
                    </span>
                  </a>
                </div>
                <div className=" w-100 h-100 ml-auto">
                  <img src={require(`../../assets/images/farms/${offering.image}`)} alt="" className="h-full w-full object-cover" />
                </div>
              </div>
            </Fade>
            <Fade bottom distance="50%">
              <div className="lg:hidden">
                <div className=" bg-grayIcon mx-5 pt-10 pb-5 mb-10">
                  <h2 className="text-5xl font-play tracking-tight leading-none text-darkBlue text-center border-b border-darkBlue pb-10 mx-5">
                    {offering.name}
                  </h2>
                  <p className=" text-grayText font-chivo text-xl mt-5 text-center">
                    Date:
                  </p>
                  <p className=" text-darkBlue mt-1 text-center text-xl font-medium">
                    {offering.date}
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-5 text-center">
                    Country:
                  </p>
                  <p className=" text-darkBlue mt-1 text-center text-xl font-medium">
                    {offering.country}
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-5 text-center">
                    Region:
                  </p>
                  <p className=" text-darkBlue mt-1 text-center text-xl font-medium">
                    {offering.region}
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-5 text-center">
                    Elevation:
                  </p>
                  <p className=" text-darkBlue mt-1 text-center text-xl font-medium">
                    {offering.elevation}
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-5 text-center">
                    Size:
                  </p>
                  <p className=" text-darkBlue mt-1 text-center text-xl font-medium">
                    {offering.size}
                  </p>
                  <p className=" text-grayText font-chivo text-xl mt-5 text-center">
                    Varietals:
                  </p>
                  <p className=" text-darkBlue mt-1 text-center text-xl font-medium px-2">
                    {offering.varietals}
                  </p>
                  <a
                    href={`${window.location.origin}/${offering.pdfName}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="border-2 border-mint uppercase py-2 relative flex justify-center cursor-pointer mt-5 mx-5"
                  >
                    <span className="text-center font-bold font-chivo text-mint">
                      Download Farm Profile
                    </span>
                  </a>
                </div>
              </div>
            </Fade>
          </div>
        ))}
      </div>
    </div>
  );
}
