import React from "react";
import brandWhite from "../../assets/images/general/brand-white.png";
import bgFooter from "../../assets/images/general/footer-bg.png";
import { ReactComponent as Aeme } from "../../assets/images/general/aeme.svg";
import { ReactComponent as Facebook } from "../../assets/images/general/fb.svg";
import { ReactComponent as Instagram } from "../../assets/images/general/ig.svg";
import { ReactComponent as Linkedin } from "../../assets/images/general/li.svg";
import { ReactComponent as Medium } from "../../assets/images/general/m.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className=" bg-darkBlue font-chivo overflow-hidden">
      <div className="lg:flex pt-5 lg:pt-10 pb-10 lg:pb-20 lg:pl-10 relative justify-between">
        <div className="flex lg:block justify-center">
          <img src={brandWhite} alt="" className=" h-16" />
        </div>

        <div className="lg:hidden relative">
          <div className="absolute right-0 -ml-10 -mr-20">
            <img src={bgFooter} alt="" className="" />
          </div>
        </div>

        <div className="relative hidden lg:block">
          <h5 className=" text-mint ">Site Map</h5>
          <ul className="mt-5 space-y-1">
            <li>
              <Link
                to="/"
                className="text-white hover:text-grayText transition duration-300 ease-in-out"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/farms"
                className="text-white hover:text-grayText transition duration-300 ease-in-out"
              >
                Farms
              </Link>
            </li>
            <li>
              <Link
                to="/mill"
                className="text-white hover:text-grayText transition duration-300 ease-in-out"
              >
                Mill
              </Link>
            </li>
            <li>
              <Link
                to="/offerings"
                className="text-white hover:text-grayText transition duration-300 ease-in-out"
              >
                Offerings
              </Link>
            </li>
          </ul>
        </div>

        <div className="relative hidden lg:block">
          <h5 className=" text-mint ">Company</h5>
          <ul className="mt-5 space-y-1">
            <li>
              <Link
                to="/about"
                className="text-white hover:text-grayText transition duration-300 ease-in-out"
              >
                About Us
              </Link>
            </li>
            <li>
              <a
                href="https://medium.com/@cafetuxpal"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-grayText transition duration-300 ease-in-out"
              >
                Blog
              </a>
            </li>
            <li>
              <Link
                to="/contact"
                className="text-white hover:text-grayText transition duration-300 ease-in-out"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className="lg:hidden flex mt-32 mb-10 justify-around text-xl">
          <div className="relative">
            <h5 className=" text-mint ">Site Map</h5>
            <ul className="mt-5 space-y-1">
              <li>
                <Link
                  to="/"
                  className="text-white hover:text-grayText transition duration-300 ease-in-out"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/farms"
                  className="text-white hover:text-grayText transition duration-300 ease-in-out"
                >
                  Farms
                </Link>
              </li>
              <li>
                <Link
                  to="/mill"
                  className="text-white hover:text-grayText transition duration-300 ease-in-out"
                >
                  Mill
                </Link>
              </li>
              <li>
                <Link
                  to="/offerings"
                  className="text-white hover:text-grayText transition duration-300 ease-in-out"
                >
                  Offerings
                </Link>
              </li>
            </ul>
          </div>

          <div className="relative">
            <h5 className=" text-mint ">Company</h5>
            <ul className="mt-5 space-y-1">
              <li>
                <Link
                  to="/about"
                  className="text-white hover:text-grayText transition duration-300 ease-in-out"
                >
                  About Us
                </Link>
              </li>
              <li>
                <a
                  href="https://medium.com/@cafetuxpal"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white hover:text-grayText transition duration-300 ease-in-out"
                >
                  Blog
                </a>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-white hover:text-grayText transition duration-300 ease-in-out"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col lg:block items-center">
          <h5 className="  text-grayText lg:text-white text-center lg:text-left uppercase lg:capitalize">
            Follow Us
          </h5>
          <ul className="mt-5 flex space-x-2">
            <li>
              <a
                href="https://www.facebook.com/CafeTuxpal"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-lightBlue h-10 w-10 rounded-full text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
              >
                <Facebook className=" fill-current h-4" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/cafetuxpal"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-lightBlue h-10 w-10 rounded-full text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
              >
                <Instagram className=" fill-current h-4" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/cafe-tuxpal"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-lightBlue h-10 w-10 rounded-full text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
              >
                <Linkedin className=" fill-current h-4" />
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/@cafetuxpal"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-lightBlue h-10 w-10 rounded-full text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
              >
                <Medium className=" fill-current h-3  mt-1" />
              </a>
            </li>
          </ul>
        </div>

        <div className="hidden lg:flex justify-end">
          <div>
            <img src={bgFooter} alt="" className="h-32 2xl:h-40" />
          </div>
        </div>
      </div>

      <div className="border-b-4 border-lightBlue flex flex-col lg:flex-row justify-between pt-5 pb-10 lg:pb-5 px-5 lg:px-10">
        <a
          href="https://mailchi.mp/407d5317c676/12aeme-studio-estudio-multidisciplinario-independiente"
          target="_blank"
          rel="noopener noreferrer"
          className=" text-grayText order-2 lg:order-1 mx-auto lg:mx-0 mt-5 lg:mt-0"
        >
          <Aeme className="h-5 fill-current" />
        </a>

        <div className=" text-grayText order-1 lg:order-2 text-center lg:text-left">
          <span className="hover:text-mint transition duration-300 ease-in-out cursor-pointer">
            Privacy Policy
          </span>{" "}
          /{" "}
          <span className="hover:text-mint transition duration-300 ease-in-out cursor-pointer">
            Cookie policy
          </span>{" "}
          <br className=" lg:hidden" />
          <br className=" lg:hidden" />
          <span className="hidden lg:inline">/</span> © Cafe Tuxpal 2020 . All
          rights reserved
        </div>
      </div>
    </div>
  );
}
