import React, { useState, useRef } from "react";
import bgMain from "../../assets/images/about/main.jpg";
import bgMainRes from "../../assets/images/about/main-res.jpg";
import about1 from "../../assets/images/about/about1.png";
import ilusAbout1 from "../../assets/images/about/ilus-about-1.png";
import { ReactComponent as ArrowSlider } from "../../assets/images/general/arrowSlider.svg";
import Slider from "react-slick";
import Fade from "react-reveal/Fade";

export default function AboutPage() {
  const [actual, setActual] = useState(0);
  const slider = useRef();

  const settings = {
    appendDots: (dots) => (
      <div
        style={{
          height: "100px",
        }}
      >
        <ul className="h-full flex justify-center mt-10">{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={`h-20 w-10 bg-white mx-2 transition duration-300 ease-in-out overflow-hidden `}
      >
        <div
          className={`h-2 w-10 bg-mint rounded-lg cursor-pointer ${
            actual === i ? "opacity-100" : " bg-opacity-50"
          }`}
        ></div>
      </div>
    ),
    beforeChange: function (currentSlide, next) {
      setActual(next);
    },
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          appendDots: (dots) => (
            <div
              style={{
                height: "100px",
              }}
            >
              <ul className="h-full flex justify-center mt-10">{dots}</ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              className={`h-2 w-2 bg-white mx-2 transition duration-300 ease-in-out overflow-hidden `}
            >
              <div
                className={`h-2 w-2 bg-grayText rounded-lg ${
                  actual === i ? "opacity-100" : " bg-opacity-50"
                }`}
              ></div>
            </div>
          ),
        },
      },
    ],
  };

  return (
    <div className="overflow-hidden">
      <div className="lg:h-header 2xl:h-headerxl -my-20 relative max-h-screen overflow-hidden">
        <Fade>
          <img
            src={bgMain}
            alt=""
            className="h-full w-full object-cover hidden lg:block"
          />
        </Fade>
        <Fade>
          <img
            src={bgMainRes}
            alt=""
            className="h-full w-full object-cover lg:hidden mt-20"
          />
        </Fade>
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-darkBlue bg-opacity-50">
          <Fade bottom distance="50%">
            <h2 className=" text-white font-medium font-play italic text-6xl lg:text-7xl textx text-center mt-32">
              Our Family History
            </h2>
          </Fade>
        </div>
      </div>

      <div className=" mt-32 lg:mt-40 bg-white mb-32 px-5">
        <Fade bottom distance="50%">
          <p className=" text-darkBlue font-chivo text-left lg:text-center lg:text-lg 2xl:text-xl leading-relaxed max-w-3xl mx-auto">
            Our family has been producing coffee since the 19th century, when
            José Rosa Pacas bought his first farm in the Apaneca-Ilamatepec
            mountain range in El Salvador where he planted bourbon varietal
            trees. Influences also come from the Diaz family, as Petrona Diaz de
            Pacas inherited Finca El Retiro in 1950 and four years later planted
            the first Pacas Varietal trees.
          </p>
          <p className="mt-5 text-darkBlue font-chivo text-left lg:text-center lg:text-lg 2xl:text-xl leading-relaxed max-w-3xl mx-auto">
            Six generations later, coffee remains our true passion and
            inspiration, while we keep improving our methods, quality and
            diversity to obtain maximum excellence in coffee. We’ve been able to
            capitalize our family’s rich experience in coffee to ensure quality
            as we produce, process and export coffee internationally.
          </p>
        </Fade>

        <div className="mt-20 2xl:mt-32 lg:flex container mx-auto">
          <div className="flex justify-center lg:w-1/2">
            <div>
              <Fade>
                <img
                  src={about1}
                  alt=""
                  className="w-auto max-w-xs 2xl:max-w-sm"
                />
              </Fade>
            </div>
          </div>
          <div className=" lg:w-1/2 mt-10 lg:mt-0">
            <Fade>
              <p className=" text-xl lg:text-4xl font-play tracking-tight text-darkBlue lg:max-w-xl font-medium italic lg:not-italic leading-relaxed">
                Symbols in our brand. Tuxpal means “Ours” in the Nahuatl
                dialect. <br className="lg:hidden" />
                The parakeet derived from an old joke among friends, who called
                Federico Sr. “La Perica”
              </p>
            </Fade>
            <Fade>
              <p className="mt-5 lg:mt-10 font-chivo text-mint text-lg lg:text-2xl font-bold lg:font-medium">
                The parakeet, for his green eye-color.
              </p>
            </Fade>
          </div>
        </div>

        <div className="mt-20 2xl:mt-32 container mx-auto lg:px-20">
          <div className="bg-lightGold relative">
            <Fade delay={1000}>
              <img
                src={ilusAbout1}
                alt=""
                className="absolute right-0 h-20 lg:h-32 2xl:h-40 mt-24 lg:mt-10 "
              />
            </Fade>
            <div className=" py-10 lg:py-16 2xl:py-20 lg:px-40">
              <Fade bottom distance="30%">
                <h3 className=" text-4xl lg:text-5xl 2xl:text-6xl font-play text-center tracking-tight text-darkBlue relative px-3">
                  The Pacas Varietal: A Family Legacy
                </h3>
              </Fade>
              <Fade bottom distance="30%">
                <p className=" mt-10 2xl:mt-20 2xl:pb-10 relative font-chivo text-lg lg:text-xl 2xl:text-2xl text-darkBlue text-left lg:text-center leading-relaxed px-5 2xl:px-10">
                  In 1958, our ancestor Fernando Alberto Pacas Figueroa noticed
                  an unusual shrub growing among bourbon coffee trees in Finca
                  San Rafael. After consulting with friends and a thorough
                  investigation in the University of Florida, it was determined
                  that this was a natural mutation from bourbon coffee trees,
                  resulting in a new coffee varietal. Nowadays, the Pacas
                  varietal is most known for its cross with the Maragogype
                  varietal, called Pacamara.
                </p>
              </Fade>
            </div>
          </div>
        </div>
        <Fade bottom distance="50%">
          <h3 className=" text-5xl 2xl:text-6xl font-play text-center tracking-tight text-darkBlue mb-10 mt-20 2xl:mt-40">
            Our History
          </h3>
        </Fade>
        <Fade bottom distance="30%">
          <div className="px-16 lg:px-24 2xl:px-40 relative -mx-5">
            <div className="absolute h-90 2xl:h-100 left-0 w-16 lg:w-24 2xl:w-40 border-b border-r border-mint flex items-center justify-center">
              <p
                onClick={() => slider.current.slickPrev()}
                className={`${actual === 0 ? "  text-grayIcon" : "text-mint"}`}
              >
                <ArrowSlider className="w-10 lg:w-16 fill-current cursor-pointer" />
              </p>
            </div>
            <div className="absolute h-90 2xl:h-100 right-0 w-16 lg:w-24 2xl:w-40 border-b border-l border-mint flex items-center justify-center">
              <p
                className={`${
                  actual === 4 ? "text-grayIcon" : "text-mint"
                } transform rotate-180`}
                onClick={() => slider.current.slickNext()}
              >
                <ArrowSlider className="w-10 lg:w-16 fill-current cursor-pointer" />
              </p>
            </div>
            <Slider dotsClass="" {...settings} ref={slider}>
              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint pb-12 lg:pb-24 transition duration-300 ease-in-out">
                    1989
                  </h5>
                  <p className=" font-chivo text-grayText text-lg">
                    Federico Pacas Diaz inherits Finca Santa Petrona and buys
                    Finca el Rosario.
                  </p>
                </div>
              </div>

              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint transition duration-300 ease-in-out pb-12 lg:pb-24">
                    1993
                  </h5>
                  <p className=" font-chivo text-grayText text-lg pb-20">
                    Tejemet SA de CV is founded by Federico Pacas Diaz.
                  </p>
                </div>
              </div>

              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint transition duration-300 ease-in-out pb-12 lg:pb-24">
                    1999
                  </h5>
                  <p className=" font-chivo text-grayText text-lg pb-20">
                    The first wet mill was installed.
                  </p>
                </div>
              </div>

              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint transition duration-300 ease-in-out pb-12 lg:pb-24">
                    2001
                  </h5>
                  <p className=" font-chivo text-grayText text-lg pb-20">
                    Café Tuxpal brand is born.
                  </p>
                </div>
              </div>

              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint transition duration-300 ease-in-out pb-12 lg:pb-24">
                    2006
                  </h5>
                  <p className=" font-chivo text-grayText text-lg pb-20">
                    Dry Mill is added.
                  </p>
                </div>
              </div>

              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint transition duration-300 ease-in-out pb-12 lg:pb-24">
                    2008
                  </h5>
                  <p className=" font-chivo text-grayText text-lg pb-20">
                    Las Marias, San Pedro, Montana and Vista Hermosa are
                    acquired.
                  </p>
                </div>
              </div>

              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint pb-12 lg:pb-24 transition duration-300 ease-in-out">
                    2012
                  </h5>
                  <p className=" font-chivo text-grayText text-lg">
                    The business passes on to the hands of Federico Pacas Lopez.
                  </p>
                  <p className=" font-chivo text-grayText text-lg mt-5 2xl:mt-10 pb-20">
                    Tejemet is registered as a Specialty Coffee Exporter.
                  </p>
                </div>
              </div>

              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint pb-12 lg:pb-24 transition duration-300 ease-in-out ">
                    2015
                  </h5>
                  <p className=" font-chivo text-grayText text-lg pb-20">
                    Finca San José is bought.
                  </p>
                </div>
              </div>

              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint pb-12 lg:pb-24 transition duration-300 ease-in-out ">
                    2020
                  </h5>
                  <p className=" font-chivo text-grayText text-lg pb-20">
                    Federico Pacas Lopez buys Finca El Corozo, located high in
                    the Santa Ana volcano.
                  </p>
                </div>
              </div>

              <div className="border-b border-r border-l border-mint focus:outline-none h-90 2xl:h-100">
                <div className="px-5 pt-5 group">
                  <h5 className="text-5xl 2xl:text-6xl font-play tracking-tight text-darkBlue group-hover:text-mint pb-12 lg:pb-24 transition duration-300 ease-in-out ">
                    2022
                  </h5>
                  <p className=" font-chivo text-grayText text-lg pb-20">
                    For many years now, our coffee is exported and enjoyed in
                    main cities in the US, Europe and Asia.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </Fade>
        <Fade bottom distance="50%">
          <h3 className=" text-5xl 2xl:text-6xl font-play text-center tracking-tight text-darkBlue mb-10 2xl:mb-20 mt-10 2xl:mt-40">
            Our Values
          </h3>
        </Fade>
        <Fade bottom distance="50%">
          <div className="lg:mx-0 lg:px-20 2xl:pb-20">
            <div className="flex flex-wrap justify-around lg:justify-center">
              <div className=" h-32 lg:h-48 2xl:h-64 w-32 lg:w-48 2xl:w-64 rounded-full bg-lightGold flex justify-center items-center">
                <span className=" font-chivo text-center text-darkBlue text-lg lg:text-xl 2xl:text-2xl">
                  Family
                </span>
              </div>
              <div className=" h-32 lg:h-48 2xl:h-64 w-32 lg:w-48 2xl:w-64 rounded-full bg-lightGold lg:bg-grayIcon flex justify-center items-center lg:-ml-8 2xl:-ml-12">
                <span className=" font-chivo text-center text-darkBlue text-lg lg:text-xl 2xl:text-2xl">
                  Excellence
                </span>
              </div>
              <div className=" h-32 lg:h-48 2xl:h-64 w-32 lg:w-48 2xl:w-64 rounded-full bg-grayIcon lg:bg-lightGold flex justify-center items-center lg:-ml-8 2xl:-ml-12 mt-3 lg:mt-0">
                <span className=" font-chivo text-center text-darkBlue text-lg lg:text-xl 2xl:text-2xl">
                  Resilience
                </span>
              </div>
              <div className=" h-32 lg:h-48 2xl:h-64 w-32 lg:w-48 2xl:w-64 rounded-full bg-grayIcon flex justify-center items-center lg:-ml-8 2xl:-ml-12 mt-3 lg:mt-0">
                <span className=" font-chivo text-center text-darkBlue text-lg lg:text-xl 2xl:text-2xl">
                  Innovation
                </span>
              </div>
              <div className=" h-32 lg:h-48 2xl:h-64 w-32 lg:w-48 2xl:w-64 rounded-full bg-lightGold flex justify-center items-center lg:-ml-8 2xl:-ml-12 mt-3 lg:mt-0">
                <span className=" font-chivo text-center text-darkBlue text-lg lg:text-xl 2xl:text-2xl">
                  Loyalty
                </span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
