import React, { useState, useEffect } from "react";
import { ReactComponent as Brand } from "../../assets/images/general/brand.svg";
import { ReactComponent as Facebook } from "../../assets/images/general/fb.svg";
import { ReactComponent as Instagram } from "../../assets/images/general/ig.svg";
import { ReactComponent as Linkedin } from "../../assets/images/general/li.svg";
import { ReactComponent as Medium } from "../../assets/images/general/m.svg";
import burguer from "../../assets/images/general/burguer.png";
import close from "../../assets/images/general/close.png";
import { Link, useLocation, NavLink } from "react-router-dom";

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  return (
    <div className="flex justify-between lg:justify-around bg-white h-24 fixed w-full font-chivo z-50 shadow-md">
      <div className="flex items-center pl-10 lg:pl-0">
        <Link to="/" className="">
          <Brand className="h-12 lg:h-16" />
        </Link>
      </div>
      <div className="flex items-center lg:hidden pr-10 lg:pr-0">
        <div onClick={() => setOpenMenu(true)}>
          <img src={burguer} alt="" />
        </div>
      </div>
      <nav className="hidden lg:block">
        <ul className="flex h-full items-center">
          <li>
            <NavLink
              to="/"
              className="mx-2 lg:mx-4 py-2 border-b-2 border-transparent hover:border-mint transition ease-in-out duration-300"
              activeClassName="border-mint"
              exact
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/farms"
              className="mx-2 lg:mx-4 py-2 border-b-2 border-transparent hover:border-mint transition ease-in-out duration-300"
              activeClassName="border-mint"
              exact
            >
              Farms
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/mill"
              className="mx-2 lg:mx-4 py-2 border-b-2 border-transparent hover:border-mint transition ease-in-out duration-300"
              activeClassName=" border-mint"
              exact
            >
              Mill
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/offerings"
              className="mx-2 lg:mx-4 py-2 border-b-2 border-transparent hover:border-mint transition ease-in-out duration-300"
              activeClassName=" border-mint"
              exact
            >
              Offerings
            </NavLink>
          </li>
          <li>
            <a
              href="https://medium.com/@cafetuxpal"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-2 lg:mx-4 py-2 border-b-2 border-transparent hover:border-mint transition ease-in-out duration-300"
            >
              Blog
            </a>
          </li>
          <li>
            <NavLink
              to="/about"
              className="mx-2 lg:mx-4 py-2 border-b-2 border-transparent hover:border-mint transition ease-in-out duration-300"
              activeClassName=" border-mint"
              exact
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className="mx-2 lg:mx-4 py-2 border-b-2 border-transparent hover:border-mint transition ease-in-out duration-300"
              activeClassName=" border-mint"
              exact
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="items-center space-x-2 hidden lg:flex">
        <span className="text-mint mr-3">Follow Us</span>
        <a
          href="https://www.facebook.com/CafeTuxpal"
          target="_blank"
          rel="noopener noreferrer"
          className=" bg-grayIcon h-10 w-10 rounded-full hover:text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
        >
          <Facebook className=" fill-current h-4" />
        </a>
        <a
          href="https://www.instagram.com/cafetuxpal"
          target="_blank"
          rel="noopener noreferrer"
          className=" bg-grayIcon h-10 w-10 rounded-full hover:text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
        >
          <Instagram className=" fill-current h-4" />
        </a>
        <a
          href="https://www.linkedin.com/company/cafe-tuxpal"
          target="_blank"
          rel="noopener noreferrer"
          className=" bg-grayIcon h-10 w-10 rounded-full hover:text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
        >
          <Linkedin className=" fill-current h-4" />
        </a>
      </div>

      <div
        className={`lg:hidden bg-darkBlue absolute w-full overflow-y-auto h-screen z-50 px-8 pb-10 text-white transform transition duration-500 ease-in-out ${
          openMenu ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="pt-10 flex items-center justify-end">
          <div className="pr-2" onClick={() => setOpenMenu(false)}>
            <img src={close} alt="" />
          </div>
        </div>
        <div className="">
          <ul className=" space-y-2 text-white font-chivo text-5xl">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/farms">Farms</Link>
            </li>
            <li>
              <Link to="/mill">Mill</Link>
            </li>
            <li>
              <Link to="/offerings">Offerings</Link>
            </li>
            <li>
              <a
                href="https://medium.com/@cafetuxpal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="mt-10">
          <h5 className=" text-grayText uppercase text-xl">Follow Us</h5>
          <ul className="mt-5 flex space-x-2">
            <li>
              <a
                href="https://www.facebook.com/CafeTuxpal"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-lightBlue h-10 w-10 rounded-full text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
              >
                <Facebook className=" fill-current h-4" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/cafetuxpal"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-lightBlue h-10 w-10 rounded-full text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
              >
                <Instagram className=" fill-current h-4" />
              </a>
            </li>
            <li>
              <Link
                to="/"
                className="bg-lightBlue h-10 w-10 rounded-full text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
              >
                <Linkedin className=" fill-current h-4" />
              </Link>
            </li>
            <li>
              <a
                href="https://medium.com/@cafetuxpal"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-lightBlue h-10 w-10 rounded-full text-white hover:bg-mint flex justify-center items-center transition ease-in-out duration-300"
              >
                <Medium className=" fill-current h-3  mt-1" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
