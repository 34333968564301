import React from "react";
import bgMain from "../../assets/images/mill/main.jpg";
import bgMainRes from "../../assets/images/mill/main-res.jpg";
import certification1 from "../../assets/images/mill/certification1.jpg";
import certification2 from "../../assets/images/mill/certification2.png";
import ilus1 from "../../assets/images/mill/ilus1.png";
import ilus2 from "../../assets/images/mill/ilus2.png";
import ilus3 from "../../assets/images/mill/ilus3.png";
import ilus4 from "../../assets/images/mill/ilus4.png";
import Slider from "react-slick";
import Buttom from "../../components/Buttom";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

export default function MillPage() {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="overflow-hidden">
      <div className="lg:h-header 2xl:h-headerxl max-h-screen -my-20 relative overflow-hidden">
        <Fade>
          <img
            src={bgMain}
            alt=""
            className="h-full w-full object-cover hidden lg:block"
          />
        </Fade>
        <Fade>
          <img
            src={bgMainRes}
            alt=""
            className="h-full w-full object-cover lg:hidden mt-20"
          />
        </Fade>
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-darkBlue bg-opacity-50">
          <Fade bottom distance="50%">
            <h2 className=" text-mint font-medium font-play italic text-6xl lg:text-7xl textx text-center mt-32">
              Wet Mill
            </h2>
          </Fade>
          <Fade bottom delay={500} distance="50%">
            <p className=" text-white text-xl text-center font-chivo mt-5 px-5">
              Our mill is equipped to process coffees in all the traditional{" "}
              <br /> methods, as well as the latest innovative processes in the
              industry.
            </p>
          </Fade>
        </div>
      </div>

      <div className="container mx-auto mt-32 lg:mt-40 px-5 lg:px-0">
        <Fade bottom distance="50%">
          <h1 className=" text-5xl lg:text-6xl font-play tracking-tight text-darkBlue">
            Processing Methods
          </h1>
        </Fade>

        <div className="lg:flex justify-between mt-5">
          <Fade bottom distance="50%">
            <div className="lg:w-1/2 lg:pr-32 relative">
              <span className="block text-5xl font-play tracking-tight text-mint">
                01
              </span>
              <h3 className=" text-darkBlue font-chivo mt-5 font-bold mb-5 text-2xl">
                Washed
              </h3>
              <p className=" text-grayText text-lg lg:text-xl font-chivo">
                In this method, we remove the cherry’s skin using a depulper.
                Then, we place it in dry fermentation tile tanks, where nature
                gets rid of most of the mucilage. Finally, the coffee is
                thoroughly washed with clean water and is later moved to dry.
              </p>
              <Fade delay={1000}>
                <img
                  src={ilus1}
                  alt=""
                  className="absolute h-10 lg:h-12 right-0 lg:mr-10 lg:mt-5"
                />
              </Fade>
            </div>
          </Fade>
          <Fade bottom distance="50%">
            <div className="lg:w-1/2 lg:pr-32">
              <span className="block text-5xl font-play tracking-tight text-mint">
                02
              </span>
              <h3 className=" text-darkBlue font-chivo mt-5 font-bold mb-5 text-2xl">
                Semi-Washed
              </h3>
              <p className=" text-grayText text-lg lg:text-xl font-chivo">
                First, the cherries are depulped and placed in dry fermentation
                tile tanks. After a brief period of time, we slightly rinse the
                coffee to avoid removing all of the mucilage, and take it to
                dry.
              </p>
            </div>
          </Fade>
        </div>

        <div className="lg:flex justify-between lg:mt-16">
          <Fade bottom distance="50%">
            <div className="lg:w-1/2 lg:pr-32">
              <span className="block text-5xl font-play tracking-tight text-mint">
                03
              </span>
              <h3 className=" text-darkBlue font-chivo mt-5 font-bold mb-5 text-2xl">
                Honey
              </h3>
              <p className=" text-grayText text-lg lg:text-xl font-chivo">
                For this method, only the outer skin of the cherries is removed
                and no washing or rinsing is done. This results in the
                honey-like mucilage covering the parchment to caramelize as it
                is set to dry. The amount of mucilage and color of the dry honey
                is used to name each process more specifically, as yellow or
                gold, red or black honey processes.
              </p>
            </div>
          </Fade>
          <Fade bottom distance="50%">
            <div className="lg:w-1/2 lg:pr-32 relative">
              <span className="block text-5xl font-play tracking-tight text-mint">
                04
              </span>
              <h3 className=" text-darkBlue font-chivo mt-5 font-bold mb-5 text-2xl">
                Natural
              </h3>
              <p className=" text-grayText text-lg lg:text-xl font-chivo">
                In this processing methods, the coffee is dried without removing
                its outer skin, which means it is dried inside the cherry.
              </p>
              <Fade delay={1000}>
                <img
                  src={ilus2}
                  alt=""
                  className="absolute w-20 lg:w-32 right-0 lg:mr-16"
                />
              </Fade>
            </div>
          </Fade>
        </div>

        <div className="lg:flex justify-between lg:mt-16">
          <Fade bottom distance="50%">
            <div className="lg:w-1/2 lg:pr-32">
              <span className="block text-5xl font-play tracking-tight text-mint">
                05
              </span>
              <h3 className=" text-darkBlue font-chivo mt-5 font-bold mb-5 text-2xl">
                Anaerobic
              </h3>
              <p className=" text-grayText text-lg lg:text-xl font-chivo">
                This innovative process is all about creating an oxygen-free
                environment while a coffee ferments. The seeds are later
                processed using any of the traditional methods and taken to dry.
              </p>
            </div>
          </Fade>
          <Fade bottom distance="50%">
            <div className="lg:w-1/2 lg:pr-32">
              <span className="block text-5xl font-play tracking-tight text-mint">
                06
              </span>
              <h3 className=" text-darkBlue font-chivo mt-5 font-bold mb-5 text-2xl">
                Carbonic Maceration
              </h3>
              <p className=" text-grayText text-lg lg:text-xl font-chivo">
                In this innovative method, coffee is fermented in a CO2-rich
                environment, and is later processed traditionally before it’s
                set to dry.
              </p>
            </div>
          </Fade>
        </div>

        <div className="relative">
          <div className="absolute inset-x-0 flex justify-center top-0">
            <div className="">
              <Fade delay={1000}>
                <img src={ilus3} alt="" className=" h-32 lg:h-40" />
              </Fade>
            </div>
          </div>
          <Fade bottom distance="50%">
            <h1 className="text-5xl lg:text-6xl font-play tracking-tight text-darkBlue mt-32 relative px-4">
              Drying Methods
            </h1>
          </Fade>
        </div>

        <div className="lg:flex justify-between mt-10 lg:mt-16">
          <Fade bottom distance="50%">
            <div className="lg:w-1/2 lg:pr-32">
              <span className="block text-5xl font-play tracking-tight text-mint">
                01
              </span>
              <h3 className=" text-darkBlue font-chivo mt-5 font-bold mb-5 text-2xl">
                Patio Drying
              </h3>
              <p className=" text-grayText text-lg lg:text-xl font-chivo">
                Coffee is placed and spread in cement patios, where it is dried
                by the sun. Our team members move the coffee around to ensure
                the coffee is evenly dried.
              </p>
            </div>
          </Fade>
          <Fade bottom distance="50%">
            <div className="lg:w-1/2 lg:pr-32">
              <span className="block text-5xl font-play tracking-tight text-mint">
                02
              </span>
              <h3 className=" text-darkBlue font-chivo mt-5 font-bold mb-5 text-2xl">
                Raised Beds (African Beds)
              </h3>
              <p className=" text-grayText text-lg lg:text-xl font-chivo">
                Coffee is spread over meshed bed-like structures, where air
                flows above and below the coffee. Our beds are placed directly
                under the sun, or under some shade, so we can elongate the
                drying period of our coffees.
              </p>
            </div>
          </Fade>
        </div>
        <div className="lg:flex justify-between mt-">
          <Fade bottom distance="50%">
            <div className="lg:w-1/2 lg:pr-32">
              <span className="block text-5xl font-play tracking-tight text-mint">
                03
              </span>
              <h3 className=" text-darkBlue font-chivo mt-5 font-bold mb-5 text-2xl">
                Mechanical Drying
              </h3>
              <p className=" text-grayText text-lg lg:text-xl font-chivo">
                This is a mechanical process where coffee is dried with hot air
                at temperatures between 35 and 40 degrees Celsius, until it
                reaches the desired percentage of humidity. Interestingly, these
                machines are fueled by burning of dried coffee husk.
              </p>
            </div>
          </Fade>
          <div className="lg:w-1/2 lg:pr-32 relative">
            <Fade delay={1000}>
              <img
                src={ilus4}
                alt=""
                className="absolute h-32 bottom-0 right-0 lg:mr-40 -mb-32 lg:mb-0"
              />
            </Fade>
          </div>
        </div>

        <div className="mt-40 mb-5 lg:mb-20">
          <Fade bottom distance="50%">
            <h3 className="text-5xl lg:text-6xl font-play text-center tracking-tight text-darkBlue">
              Dry Mill
            </h3>
          </Fade>
          <Fade bottom delay={500} distance="50%">
            <p className=" text-center text-grayText text-lg lg:text-xl font-chivo mt-5">
              After coffee is dried, we apply various methods to collect the
              best <br className="hidden lg:block" /> beans that will later
              become the best cups of coffee.
            </p>
          </Fade>
        </div>

        <div className="flex justify-center">
          <span className="border-2 border-gold h-32 lg:h-48"></span>
        </div>
      </div>

      <div className=" bg-grayIcon -mt-10">
        <Fade>
          <div className="py-20 container mx-auto lg:flex justify-around lg:space-x-10">
            <div className="lg:w-1/3">
              <div className="pb-10 mx-auto max-w-sm">
                <h4 className="text-darkBlue text-center text-3xl 2xl:text-4xl font-chivo font-bold">
                  Hulling
                </h4>
                <p className=" text-center text-grayText mt-5 font-chivo px-5 lg:px-0">
                  A machine is used to remove the dry skin or parchment from the
                  dried coffee beans, carefully delivering green beans that are
                  ready for sorting and quality assurance.
                </p>
              </div>
            </div>

            <div className="lg:w-1/3">
              <div className="pb-10 mx-auto max-w-sm">
                <h4 className="text-darkBlue text-center text-3xl 2xl:text-4xl font-chivo font-bold">
                  Selection and Grading
                </h4>
                <p className=" text-center text-grayText mt-5 font-chivo px-5 lg:px-0">
                  Coffee beans are categorized by size, shape, density, and UV
                  light inspection to remove any defects from a batch.
                </p>
              </div>
            </div>

            <div className="lg:w-1/3">
              <div className="pb-10 mx-auto max-w-sm">
                <h4 className="text-darkBlue text-center text-3xl 2xl:text-4xl font-chivo font-bold">
                  Packaging
                </h4>
                <p className=" text-center text-grayText mt-5 font-chivo px-5 lg:px-0">
                  Our coffee is then packaged into hermetic bags and jute bags
                  in 69 KG, 35 KG, smaller boxes or bulk presentations, ready to
                  be exported.
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </div>

      <div className=" bg-white container mx-auto mt-10 lg:mt-20">
        <Fade bottom distance="50%">
          <h3 className=" text-5xl lg:text-6xl font-play text-center tracking-tight text-darkBlue px-5 lg:px-0">
            Mill Certifications
          </h3>
        </Fade>
        <Fade>
          <div className="mt-10 flex flex-col lg:flex-row items-center justify-center lg:space-x-10">
            <div className="">
              <img src={certification1} alt="" className="h-32 lg:h-40" />
            </div>
            <div className="mt-5 lg:mt-0">
              <img src={certification2} alt="" className="h-20 lg:h-24" />
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="mt-20 bg-gold lg:bg-lightGold lg:h-32 flex flex-col lg:flex-row items-center justify-between py-20 lg:py-0 lg:px-10 lg:mb-32 mx-0 lg:mx-20">
            <h3 className="text-4xl font-play text-center tracking-tight text-darkBlue mb-20 lg:mb-0">
              Get a Quote
            </h3>
            <div className="hidden lg:block">
              <Buttom
                link="/contact"
                label="Contact Us"
                goldBorder
                capitalize
              />
            </div>
            <Link
              to="/contact"
              className="border-2 border-white py-4 px-10 font-chivo text-white font-bold lg:hidden"
            >
              CONTACT US
            </Link>
          </div>
        </Fade>
      </div>
    </div>
  );
}
